import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/emp',
    name: 'emp',
    component: () => import('../views/element/tlias/EmpView.vue')
  },
  {
    path: '/dept',
    name: 'dept',
    component: () => import('../views/element/tlias/DeptView.vue')
  }, {
    path: '/main',
    name: 'main',
    component: () => import('../views/element/main/MainView.vue'),
    meta: { title: '首页' },
    children: [
      {
        path: 'filesManage',
        component: () => import('../views/element/upload/FileManage.vue')
      }, {
        path: 'uploadFiles',
        component: () => import('../views/element/upload/UploadView.vue')
      }, {
        path: '/',
        redirect: '/main/filesManage'
      }
    ]
  },{
    path: '/login',
    name: 'login',
    component: () => import('../views/element/login/LoginView.vue')
  }
]

const router = new VueRouter({
  routes
})
export default router

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

<template>
<div>
  <!-- <element-view></element-view> -->
  <!-- <emp-view></emp-view> -->
  <router-view></router-view>
</div>
</template>

<style>
body{
  margin:0;
  padding:0;
  border:0
}
</style>

<script>
// import MainView from './views/element/main/MainView.vue'

// import DeptView from './views/element/tlias/DeptView.vue'

// import EmpView from './views/element/tlias/EmpView.vue'
// import ElementView from './views/element/ElementView.vue'
export default {
  components: {/* DeptView *//* EmpViewDeptView */ /* ElementView */ },
}
</script>